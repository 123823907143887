import * as React from 'react'
import {  Link } from 'gatsby'
import Layout from '../../components/layout'
import '../../styles/global.css' 
import {Helmet} from "react-helmet";


export default function DisappearingWall() {
  return (
    <Layout pageTitle="Disappearing Wall">

      <Helmet>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <title>Disappearing Wall - USI Projects</title>
        <meta name="description" content="The Disappearing Wall is a public art installation commissioned by the Goethe Institut in 8 cities across Europe."/>
        <meta property="og:title" content="Urban Scale Interventions" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://urbanscaleinterventions.com/projects" />
        <meta property="og:image" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>

      <section class="text-gray-700 body-font heading-container-wall">
      <div class="relative mx-auto h-full px-5 md:px-16 py-24 items-center">
          <section class="self-end">
              <div class="absolute bottom-10">
                <h3 class="text-5xl lg:text-8xl font-heading leading-tight text-white pt-16">Disappearing Wall</h3>
                <p class="text-white text-xl md:text-2xl lg:mt-0"><span class="font-bold">Client: </span>
                Goethe Institut</p>
              </div>
          </section>
        </div>
      </section>


      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 pt-12 lg:pt-24 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap ">
              <div class="md:w-3/4 py-4 md:px-32 mb-4 md:mb-0">
                <h3 data-scroll data-scroll-speed="1" class="text-6xl font-heading">Overview</h3>
                <p data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                  class="my-4 text-black leading-relaxed text-xl md:text-2xl lg:text-3xl ">The
                  Disappearing Wall is a public art installation commissioned by the Goethe Institut in 8 cities
                  across Europe to celebrate Germany's EU council presidency 2020. The interactive art installation
                  celebrates Europe’s diversity of languages and ideas. The Wall consists of a plexiglas frame which
                  houses 12,000 wooden blocks engraved with original and translated quotes collected through an open
                  call across Europe.<br/><br/>

                  USI Worked with the Goethe Institut and Catalyst Arts to produce and install the wall on the iconic
                  Titanic Slipways in Belfast. Visitors to the Wall were encouraged to take home a block which
                  contained one of a wide range of quotes from the likes of Albert Einstein, Adorno, Rosa Luxemburg,
                  Sartre and Jonas Mekas, to Beatles’ lyrics and lines from Winnie the Pooh or the film
                  Amélie.<br/><br/>

                  As more and more blocks were removed, the Wall ‘disappeared’.</p>

              </div>
              <div class="md:w-1/4 py-4 md:px-8 mb-4 md:mb-0 md:mt-28">
                <p class="text-2xl font-bold uppercase">Fields of
                  Practice</p>
                <p class="my-4 text-gray-500 leading-relaxed">Culture<br/>
                  Tourism<br/>Regeneration<br/>Communities<br/>Placemaking</p>
                <p class="text-2xl font-bold uppercase">Services</p>
                <p class="my-4 text-gray-500 leading-relaxed">Culture<br/>
                  Public Engagement<br/>Technical Design<br/>Project Management</p>
                <p class="text-2xl font-bold uppercase">Location</p>
                <p class="my-4 text-gray-500 leading-relaxed">Titanic Slipways,
                  Belfast</p>
              </div>
            </div>
          </section>
        </div>
      </section>



      <div data-scroll data-scroll-class="scroll-class" data-scroll-repeat="true" class="md:p-16 md:m-16 py-6">
        <video controls src="https://player.vimeo.com/external/485418759.hd.mp4?s=b63522455279a50c0e438abd0ec17f852e207072&profile_id=175">
        </video>
      </div>

      <img class="w-full " src="../img/projects/bg/wall.png" alt=""/>



      <img data-scroll data-scroll-offset="200" data-scroll-speed="2" class="w-full md:pl-20 md:pr-20 py-6"
        src="https://ik.imagekit.io/usi/project-pages/DSC00986_1_GQPpIhoC-6.png" alt=""/>

      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 py-16 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap">
              <div class="w-full md:px-40 xl:w-6/12 py-4 xl:px-32 mb-4 md:mb-0">

                <p data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                  class="text-xl md:text-2xl lg:text-3xl  md:pt-9 p-6 md:pl-0 md:pr-0 md:pb-0 md:mb-16 md:mr-20">
                  Installed during the Covid-19 lockdown for NI, over 10,000 people visited the wall over 2 weeks and
                  took a block in Covid safe conditions. A further 110,000 people interacted with the wall through
                  social media, with some blocks sent out in the post to those that couldn't attend the wall in
                  person.<br/><br/>

                  The Wall highlighted the importance of cultural installations in getting people to explore new parts
                  of the city, during a time of increased stress for the population of the city.
                </p>

                <div data-scroll data-scroll-speed="-4" data-scroll-direction="horizontal" class="self-end mr-12">
                  <p class="text-xl md:text-2xl  md:pt-9 p-6 md:pl-0 md:pr-0 md:pb-0 -mb-6 md:text-right">
                    During a pandemic
                  </p>
                  <h2 class="text-5xl mt-4 leading-tight font-semibold font-heading text-primaryyellow md:text-right">
                    10,000 in 2
                    weeks</h2>
                </div>

              </div>

              <div class="w-full xl:w-6/12 py-4 mb-4 md:mb-0">

        
                <img class="" src="../img/projects/rainbow.png" alt="foyle app"/>

              </div>
            </div>
          </section>
        </div>
      </section>




      <img class="w-full" src="https://ik.imagekit.io/usi/project-pages/titanic_g1r4lxQ32Qvt5.png" alt="titanic"></img>


      <div class="bg-gray-200 py-16">
        <div class="text-center">
          <h3
            class="mt-2 text-3xl leading-8 font-extrabold tracking-tight sm:text-5xl sm:leading-10 font-heading text-blue-900">
            Explore more work
          </h3>
          <p class="mt-4 max-w-2xl text-xl md:text-2xl  leading-7 text-black mx-12 md:mx-auto pt-6">
            Want to see more? Take a look at the way we’re responding to changes in the way we live, work and
            play.
          </p>
        </div>

        <div class="max-w-screen grid grid-cols-1 md:grid-cols-2 text-center">
          <div class="">
            <Link to="../../projects" class="carrow cta font-heading flipped text-gray-500 text-sm">All Projects
              <span className='hidden sm:block'></span>
            </Link>
          </div>
          <div class="">
            <Link to="../fermanagh" class="carrow cta font-heading text-gray-600">Next Project
              <span className='hidden sm:block'></span>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}
